<template>
  <div class="left">
    <div class="item">{{categoryName}}</div>
    <div class="item" @click="handleClick(item)" :class="index == item.id?'curr':''" v-for="(item,i) in list" :key="item.id">{{item.categoryName}}</div>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      type: Array,
      default: []
    },
    index: {
      type: [Number,String],
      default: 1
    },
    categoryName: {
      type: String,
      default: ''
    },
  },
  data() {
    return {

    }
  },
  methods: {
    handleClick(item) {
      this.$emit('click',item)
    }
  }
}
</script>
<style lang="less" scoped>
.left {
  width: 350px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  .item {
    width: 100%;
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #909097;
    &:not(:first-child) {
      cursor: pointer;
    }
    &:first-child {
      background: url(../assets/img/icon_left_bg.png) no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: 24px;
      height: 120px;
    }
    &.curr {
      color: #0d53bc;
      position: relative;
      &::after {
        position: absolute;
        left: 0;
        width: 4px;
        height: 60px;
        background: #0d53bc;
        content: "";
      }
    }
  }
}
</style>
