<template>
  <div class="header flexHC main">
      <img src="@/assets/logo.svg" alt="">
      <div>
        <p class="title">中国服务贸易协会信息技术服务委员会</p>
        <p class="desc">Information Technology Services Union of China Association of Trade in Services</p>
<!--        <img src="@/assets/header.svg" style="width: 405px; border-radius: 0px;">-->
      </div>
    </div>
</template>
<script>
export default {

}
</script>
<style lang="less" scoped>
.header  {
  padding: 20px 0;
  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 16px;
  }
  .title {
    height: 40px;
    font-size: 28px;
    font-weight: bold;
    color: #1B252C;
    line-height: 40px;
    letter-spacing: 1.5px;
  }
  .desc {
    height: 17px;
    font-size: 13px;
    font-weight: 400;
    color: #1B252C;
    line-height: 17px;
  }
}
</style>
