// const nodeENV = process.env.NODE_ENV;
// const VUE_APP_FLAG = process.env.VUE_APP_FLAG;

// let config = {};
// /* if (nodeENV === "development") {
//   config = require("./dev");
// } else if (nodeENV === "si") {
//   config = require("./si");
// } else if (nodeENV === "st") {
//   config = require("./st");
// } else if (nodeENV === "prod") {
//   config = require("./prod");
// } else {
//   config = require(`./${VUE_APP_FLAG}`);
// } */

// config = require(`./${VUE_APP_FLAG}`);

// module.exports = {
//   ...config
// };

const VUE_APP_FLAG = process.env.VUE_APP_FLAG;
let domain = {};

domain = require(`./${VUE_APP_FLAG}`);
module.exports = {
	...domain,
};

