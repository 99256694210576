import Vue from 'vue'
import App from './App.vue'
import router from './router'
import xnHeader from '@/components/xnHeader'
import xnNav from '@/components/xnNav'
import xnFooter from '@/components/xnFooter'
import xnLeft from '@/components/xnLeft'

import { Carousel,CarouselItem, Dialog, Pagination, Form, FormItem, Input } from 'element-ui';
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Dialog)
Vue.use(Pagination)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)

Vue.component('xnHeader', xnHeader)
Vue.component('xnNav', xnNav)
Vue.component('xnFooter', xnFooter)
Vue.component('xnLeft', xnLeft)

Vue.config.productionTip = false


import 'normalize.css'
import '@/style/index.less'

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
