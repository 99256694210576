<template>
  <div id="app" class="app-container">
    <xn-header></xn-header>
    <xn-nav></xn-nav>
    <router-view v-if="isRouterAlive" />
    <xn-footer></xn-footer>
  </div>
</template>
<script>
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
}
</script>
<style lang="less">
.main {
  width: 1280px;
  margin: 0 auto;
}
</style>
