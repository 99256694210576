import Vue from 'vue'
import axios from 'axios'
import domain from "@/env-config/index"
import qs from 'qs'
const vm = new Vue()
let loading = null
const instance = axios.create({
    timeout: 50000,
  // baseURL: 'http://cms.xianniu.cn/web'
    baseURL: domain.phpUrl
})
// const showLoading = () => {
//   loading = vm.$tools.byToast({
//     type: 'loading',
//     message: '请稍后...',
//     duration: 0
//   })
// }
// const hideLoading = () => {
//   loading.clear()
// }

instance.interceptors.request.use(
  config => {
    if (config.type === 'formData') {
      // 序列化可以用这种  注意 new FormData 后 不能qs.stringify
      config.headers['content-type'] = 'application/x-www-form-urlencoded'
      config.data = qs.stringify(config.data)
    }
    const token = window.localStorage.getItem("token")?window.localStorage.getItem("token"): null
    if (token) {
      config.headers['token'] = token
    }
    config.headers['webTenantId'] = 5
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
instance.interceptors.response.use(
  response => {
    const res = response.data
    if (loading) {
      hideLoading()
    }
    if (res.code !== 200) {
      // vm.$tools.byToast({
      //   message: `${res.message}`,
      //   position: 'bottom'
      // })
      // if(res.errorCode == 'SETTLEMENT_CENTER_QR_CODE_0002') {
      //   return res
      // }
      // return Promise.reject(res.message)
    }
    return res
  },
  error => {
    // if (loading) {
    //   hideLoading()
    // }

    // if (error.message.indexOf('Network Error') !== -1) {
    //   vm.$tools.byToast({
    //     message: `Network Error`,
    //     position: 'bottom'
    //   })
    //   return Promise.reject(error)
    // }
    // if (error.message.indexOf('timeout') !== -1) {
    //   vm.$tools.byToast({
    //     message: `请求超时`,
    //     position: 'bottom'
    //   })
    //   return Promise.reject(error)
    // }
    // if (error && error.response) {
    //   switch (error.response.status) {
    //     case 500:
    //       vm.$tools.byToast({
    //         message: `服务器内部错误111`,
    //         position: 'bottom'
    //       })
    //       break
    //     case 404:
    //       vm.$tools.byToast({
    //         message: `接口请求地址错误`,
    //         position: 'bottom'
    //       })
    //       break
    //     case 400:
    //       vm.$tools.byToast({
    //         message: `请求无效`,
    //         position: 'bottom'
    //       })
    //       break
    //   }
    // }
    // alert('请求报错')
    return Promise.reject(error)
  }
)
class Http {
  static async get(url, params, isShow = true) {
    return await instance.get(url, { params })
  }
  static async put(url, params, isShow = true) {

    return await instance.put(url, params)
  }
  static async post(url, params, isShow = true,config={}) {
    return await instance.post(url, params,config)
  }
  static async formData(url, params,isShow = true,config={}) {
    //  new FormData()  请求头content-type 默认为 multipart/form-data
    const formData = new FormData()
    for(let key in params) {
      formData.append(key, JSON.stringify(params[key]) )
    }
    return await instance.post(url, formData,config)
  }
}

export default Http
