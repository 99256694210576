import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: { title: '首页' },
    component: () => import(/* webpackChunkName: "index" */ '../views/index.vue')
  },
  {
    path: '/article',
    name: 'detail',
    meta: { title: '文章详情' },
    component: () => import(/* webpackChunkName: "index" */ '../views/detail.vue')
  },
  {
    path: '/list',
    name: 'list',
    meta: { title: '文章列表' },
    component: () => import(/* webpackChunkName: "index" */ '../views/list.vue')
  },
  {
    path: '/briefInfo',
    name: 'briefInfo',
    meta: { title: '中国服务贸易协会信息技术服务委员会简介' },
    component: () => import(/* webpackChunkName: "index" */ '../views/branch/briefInfo.vue')
  },
  {
    path: '/leader',
    name: 'leader',
    meta: { title: '分会领导' },
    component: () => import(/* webpackChunkName: "index" */ '../views/branch/leader.vue')
  },
  {
    path: '/organize',
    name: 'organize',
    meta: { title: '组织机构' },
    component: () => import(/* webpackChunkName: "index" */ '../views/branch/organize.vue')
  },
  {
    path: '/company',
    name: 'company',
    meta: { title: '发起单位' },
    component: () => import(/* webpackChunkName: "index" */ '../views/member/company.vue')
  },
  {
    path: '/allCompany',
    name: 'allCompany',
    meta: { title: '企业名录' },
    component: () => import(/* webpackChunkName: "index" */ '../views/member/allCompany.vue')
  },
  {
    path: '/duty',
    name: 'duty',
    meta: { title: '主要职责' },
    component: () => import(/* webpackChunkName: "index" */ '../views/expert/duty.vue')
  },
  {
    path: '/expert',
    name: 'expert',
    meta: { title: '分会专家' },
    component: () => import(/* webpackChunkName: "index" */ '../views/expert/expert.vue')
  },
  {
    path: '/book',
    name: 'book',
    meta: { title: '白皮书' },
    component: () => import(/* webpackChunkName: "index" */ '../views/expert/book.vue')
  },
  {
    path: '/formulate',
    name: 'formulate',
    meta: { title: '中国服务贸易协会信息技术服务委员会' },
    component: () => import(/* webpackChunkName: "index" */ '../views/standard/formulate.vue')
  }

]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
