import { render, staticRenderFns } from "./xnLeft.vue?vue&type=template&id=3033d6bd&scoped=true&"
import script from "./xnLeft.vue?vue&type=script&lang=js&"
export * from "./xnLeft.vue?vue&type=script&lang=js&"
import style0 from "./xnLeft.vue?vue&type=style&index=0&id=3033d6bd&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3033d6bd",
  null
  
)

export default component.exports