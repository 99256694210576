<template>
  <div class="nav-box">
      <div class="main flexHC pl-16" style="height: 100%; font-size: 16px; justify-content: space-between;">
        <div class="category-div" @click="goToIndex">首页</div>
        <div
            class="category-div"
            v-for="(item,i) in categoryList"
            :key="i"
        >
          <div @click="goToArticleList(item.id)">
            {{item.categoryName}}
          </div>
          <div class="category-children-box" >
            <div class="category-children-div" v-for="(childItem, cateId) in item.children" :key="cateId" @click="goToArticleList(item.id, childItem.id)">
              {{childItem.categoryName}}
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { getTree } from '@/api/articleCategory'
export default {
  data() {
    return {
      currParentId: 0,
      currCategoryId: 0,
      categoryList: [],
      articleListUrl: '/list',
      indexUrl: '/'
    }
  },

  methods: {
    // 一级分类列表
    getCategoryList() {
      const _t = this
      getTree().then((result)=>{
        if(result.status === 'OK'){
          _t.categoryList = result.data
        }
      })
    },
    // 跳转到文章列表页
    goToArticleList(parentId, categoryId){
      //if(parentId == this.currParentId) return
      //this.currParentId = parentId
      if(categoryId && categoryId == this.currCategoryId) return
      this.currCategoryId = categoryId
      this.$router.push({
        path: this.articleListUrl,
        query: {
          parentId: parentId,
          categoryId: categoryId
        }
      })
    },

    goToIndex(){
      this.$router.push({
        path: this.indexUrl,
      })
    },
    openChildren(row){
      row.showChild = 1;
    },
    closeChildren(row){
      row.showChild = 0;
    }
  },
  created() {
    this.getCategoryList()
  }
}
</script>
<style lang="less" scoped>
.nav-box {
  width: 100%;
  height: 60px;
  background: #0D53BC;
  min-width: 1280px;

  .category-div {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    color: #fff;
    // margin-right: 58px;
    cursor: pointer;
    &:hover .category-children-box {
      display: block;
    }
    .category-children-box{
      display: none;
      position: absolute;
      left: -16px;
      background: #0d53bc;
      font-size: 14px;
      z-index: 999;
      top: 57px;
      border-top: 3px solid #fff;
      width: max-content;
      border-radius: 3px;
      .category-children-div{
        height: 100%;
        display: flex;
        align-items: center;
        color: #fff;
        cursor: pointer;
        padding: 10px 18px;
        transition: all 0.3s;
      }
    }
  }
  .category-div:last-of-type{
    height: 32px;
    line-height: 32px;
    padding: 0 8px;
    background: #0091FF;
    border-radius: 4px;
  }
}
.category-children-div:hover{
  background: #fff;
  color: #0d53bc !important;
}
</style>
