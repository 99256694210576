<template>
  <div class="footer-box">
    <div class="main flexHB">
      <div class="left">
        <p class="name">中国服务贸易协会信息技术服务委员会</p>
        <p class="desc mt-2">
          <img src="@/assets/img/Information_Technolo.svg" alt="">
        </p>
        <p class="addr mt-38">办公地址：北京市朝阳区建国路93号 京仪大厦4层</p>
        <p class="phone mt-6">办公电话：010-88500588</p>
      </div>
      <div class="nav-box flexH">
        <ul v-for="item in categoryTree">
          <li @click="goToArticleList(item.id)" style="cursor: pointer;">{{item.categoryName}}</li>
          <li v-for="child in item.children" @click="goToArticleList(item.id, child.id)" style="cursor: pointer;">{{child.categoryName}}</li>
        </ul>
      </div>
    </div>
    <div class="main flexHB" style="padding-top: 40px;">
      <div class="footer-site-info">
        <p class="footer-copyright">
          © 2023-2024
          <a target="_blank" href="https://itsu.org.cn/">itsu.org.cn</a> 版权所有
          <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">ICP备案证书号:京ICP备18030884号-3</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { getTree } from '@/api/articleCategory'
export default {
  name: 'xnFooter',
  data() {
    return {
      categoryTree: [],
      articleListUrl: '/list',
      currParentId: '',
      currCategoryId: ''
    }
  },
  methods: {
    // 分类树
    getCategoryTree() {
      const _t = this
      getTree().then((result)=>{
        if(result.status === 'OK'){
          _t.categoryTree = result.data
        }
      })
    },
    // 跳转文章列表
    goToArticleList(parentId, categoryId){
      if(categoryId && categoryId == this.currCategoryId) return
      this.currCategoryId = categoryId
      this.currParentId = parentId

      this.$router.push({
        path: this.articleListUrl,
        query: {
          parentId: parentId,
          categoryId: categoryId
        }
      })
    }
  },
  created() {
    this.getCategoryTree()
  }
}
</script>
<style lang="less" scoped>
.footer-box {
  width: 100%;
  height: 360px;
  background: #000B1A;
  color: #fff;
  padding-top: 63px;
  min-width: 1280px;
}
.left {
  .name {
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 33px;
    letter-spacing: 0.5px;
  }
  .desc {
    font-size: 10px;
    color: #C1C1C9;
    line-height: 14px;
  }
  .addr,.phone  {
    font-size: 14px;
    color: #C1C1C9;
    line-height: 20px;
  }
}

.nav-box {
  ul {
    margin-right: 28px;
    flex-shrink: 0;
    &:last-child {
      margin-right: 0;
    }
    li {
      &:first-child {
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
      }
      font-size: 12px;
      color: #C1C1C9;
      line-height: 17px;
      margin-bottom: 8px;
    }
  }
}
</style>
<style scoped>
.footer-site-info {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  padding: 30px 0;
  opacity: .6;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  text-align: center;
}
.footer-copyright {
  display: inline-block;
  text-align: left;
}
a, a:active {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  border: 0;
}
</style>
